import React, { useContext, useCallback } from 'react';
import { appUIActionContext, appUIStateContext } from '../App/contexts';
import Component from './component';
import { toggleAppModal } from '../../state/ui/actions';

function AppModalConsumer() {
  const executeAppUIAction = useContext(appUIActionContext);
  const appUIstate = useContext(appUIStateContext);

  const handleClose = useCallback(() => {
    executeAppUIAction(toggleAppModal(false));
  }, []);

  return (
    <Component
      show={appUIstate['app.modal.open']}
      component={appUIstate['app.modal.component']}
      onClose={handleClose}
      disableClose={appUIstate['app.modal.disableClose']}
    />
  );
}

export default React.memo(AppModalConsumer);
