import { useState, useCallback, useRef, useEffect, memo, ReactNode } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { promptForHubUpdate, removeLegacyServiceWorker } from '@client/utils/prompt-for-hub-update';
import { setDatadogUser } from '@client/utils/datadog';
import {
  AppStateContext,
  appContext,
  appStateContext,
  appActionContext,
  appUIStateContext,
  appUIActionContext,
  appErrorStateContext,
  appErrorActionContext
} from './contexts';
import {
  getJWT,
  isJWTValid,
  isValidHUBDashobardUser,
  getDepartment,
  clearAuthTokens,
  DecodedJWTToken
} from '../../utils/auth';
import getNestedObject from '../../utils/getNestedObject';
import { retrieveAllRegions } from '../../state/resources/regions/actions';
import { retrieveAllShipperPreferences } from '../../state/resources/shipperPreferences/actions';

const AppProviderMemo = memo(
  (props: any) =>
    // eslint-disable-next-line react/prop-types
    props.children
);

/**
 * Controls how often the app will check for updates in milliseconds.
 */
const POLLING_PERIOD_MS = 30000;

export default function AppProvider(props: { children: ReactNode }) {
  const [state, setState] = useState<AppStateContext>({
    'auth.userInfo': null,
    'auth.selectedDepartment': null,
    'ui.leftSidebar.open': false,
    'ui.leftSidebarForArAp.open': false,
    'ui.leftSidebar.component': null
  });

  const ldClient = useLDClient();
  let jwt: DecodedJWTToken | null = null;

  if (!state['auth.userInfo'] || !state['auth.selectedDepartment']) {
    jwt = getJWT();
    const department = getNestedObject(jwt, ['departments', 0]);

    state['auth.userInfo'] = jwt;
    state['auth.selectedDepartment'] = department;
  }

  const selectedDepartment = getDepartment();

  if (selectedDepartment && !isValidHUBDashobardUser(selectedDepartment.type)) {
    clearAuthTokens();
  }

  const [appUIState, setAppUIState] = useState({
    'ui.rightSidebar.open': false
  });
  const [appErrorState, setAppErrorState] = useState({});

  const executeAppAction = useCallback(
    (action: any) =>
      action(setState, {
        setAppErrorState
      }),
    []
  );

  const executeAppUIAction = useCallback((action: any) => action(setAppUIState), []);

  const executeAppErrorAction = useCallback((action: any) => action(setAppErrorState), []);

  const appContextValueRef = useRef({ state, executeAction: executeAppAction });

  if (state !== appContextValueRef.current.state) {
    appContextValueRef.current = {
      state,
      executeAction: executeAppAction
    };
  }

  useEffect(() => {
    const identifyUser = async () => {
      if (jwt) {
        setDatadogUser({
          id: jwt.uuid,
          email: jwt.email1,
          name: `${jwt.firstName} ${jwt.lastName}`
        });

        await ldClient?.identify({
          key: jwt.uuid,
          firstName: jwt.firstName,
          lastName: jwt.lastName,
          email: jwt.email1
        });
      }
    };

    if (jwt && selectedDepartment && isJWTValid() && isValidHUBDashobardUser(selectedDepartment.type)) {
      executeAppAction(retrieveAllRegions());
      executeAppAction(retrieveAllShipperPreferences());
      identifyUser();
    }
  }, [jwt, selectedDepartment]);

  useEffect(() => {
    const intervalId = window.setInterval(promptForHubUpdate, POLLING_PERIOD_MS);

    removeLegacyServiceWorker();

    return () => {
      window.clearInterval(intervalId);
    };
  }, [promptForHubUpdate]);

  return (
    <appContext.Provider value={appContextValueRef.current}>
      <appStateContext.Provider value={state}>
        <appActionContext.Provider value={executeAppAction}>
          <appUIStateContext.Provider value={appUIState}>
            <appUIActionContext.Provider value={executeAppUIAction}>
              <appErrorStateContext.Provider value={appErrorState}>
                <appErrorActionContext.Provider value={executeAppErrorAction}>
                  <AppProviderMemo>{props.children}</AppProviderMemo>
                </appErrorActionContext.Provider>
              </appErrorStateContext.Provider>
            </appUIActionContext.Provider>
          </appUIStateContext.Provider>
        </appActionContext.Provider>
      </appStateContext.Provider>
    </appContext.Provider>
  );
}
