import React from 'react';
import AngleDown from '@components/deprecatedTookit/icons/fa/solid/CaretDownIcon';
import AngleUp from '@components/deprecatedTookit/icons/fa/solid/CaretUpIcon';
import PropTypes from 'prop-types';
import Button from '@components/deprecatedTookit/Button';
import { useAppStateContext, useAppActionContext } from '../App/contexts';
import { setDepartment, getDepartment } from '../../utils/auth';

import './style.css';

function AccountDropdown(props) {
  const appState = useAppStateContext();
  const appDispatch = useAppActionContext();

  const { isOpen } = props;

  const userInfo = appState['auth.userInfo'];

  const selectDepartment = (department) => {
    setDepartment(department);
    appDispatch((setState) => setState({ ...appState, 'auth.selectedDepartment': department }));
  };

  const selectedDepartment = getDepartment();

  let options;

  const isDepartmentSelected = (department) =>
    department.id === selectedDepartment.id && 'account-dropdown-buttons--selected';

  if (isOpen) {
    options = (
      <>
        <div className="account-dropdown-departments">
          <h3 className="account-dropdown-title-account">Departments</h3>

          {userInfo.departments &&
            userInfo.departments.map((department) => (
              <div
                key={department.uuid}
                className={`${isDepartmentSelected(department)} account-dropdown-buttons`}
                onClick={() => selectDepartment(department)}
              >
                <p>{department.nickname || department.name || department.type}</p>
              </div>
            ))}
        </div>
        <div className="account-dropdown-accounts">
          <h3 className="account-dropdown-title-account">Account</h3>
          <div className="account-dropdown-buttons" onClick={props.requestLogout}>
            <p>Sign Out</p>
          </div>
        </div>
      </>
    );
  } else {
    options = [];
  }

  return (
    <div className="account-dropdown-wrapper">
      {isOpen ? (
        <AngleUp aria-label="close account dropdown" className="account-dropdown-icon" />
      ) : (
        <AngleDown aria-label="open account dropdown" className="account-dropdown-icon" />
      )}
      <Button className="account-dropdown-title" />
      <div
        className="account-dropdown-buttons-wrapper"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {options}
      </div>
      {isOpen && <div className="clickaway" />}
    </div>
  );
}

AccountDropdown.propTypes = {
  requestLogout: PropTypes.func,
  isOpen: PropTypes.bool
};

export default AccountDropdown;
