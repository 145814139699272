/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { setUserProperties } from '@client/utils/analytics';
import { fetchDepartments } from '@client/_blessed/store/entities';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import PrivateRoute from '../PrivateRoute';
import Toaster from '../Toaster';
import ErrorToastManager from '../ErrorToastManager';
import NotFoundPage from '../../pages/NotFoundPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import RegisterPage from '../../_blessed/components/features/Register';
import RequestPasswordResetPage from '../../pages/RequestPasswordResetPage';
import DashboardPageLayout from '../../layouts/DashboardPageLayout';
import AppProvider from './provider';
import history from '../../utils/history';
import AppModal from '../AppModal';
import History from '../History';
import MaintenancePage from '../../pages/MaintenancePage';
import ScrollToTop from '../ScrollToTop/component';

export default function App() {
  const dispatch = useAppDispatch();
  const ldFlags = useFlags();
  const systemMaintenanceFlag = ldFlags.systemUnderMaintenance;
  const hubMaintenanceCompletionETA = systemMaintenanceFlag && systemMaintenanceFlag.hubMaintenanceCompletionETA;

  useEffect(() => {
    setUserProperties();
    const departmentsPromise = dispatch(fetchDepartments());

    return () => {
      departmentsPromise.abort();
    };
  }, []);

  return (
    <AppProvider>
      <History />
      <Router history={history}>
        {hubMaintenanceCompletionETA ? (
          <Route
            exact
            path="*"
            // eslint-disable-next-line react/no-unstable-nested-components
            component={() => <MaintenancePage time={hubMaintenanceCompletionETA} />}
          />
        ) : (
          <ScrollToTop>
            <Switch>
              <Redirect exact from="/" to="/hub/dashboard" />
              <Redirect exact from="/hub" to="/hub/dashboard" />
              <Redirect from="/hub/login" to="/hub/dashboard" />
              <Route path="/hub/register" component={RegisterPage} />
              <PrivateRoute path="/hub/dashboard" component={DashboardPageLayout} />
              <Route path="/hub/password-reset/:token" component={ResetPasswordPage} />
              <Route exact path="/hub/password-reset-request" component={RequestPasswordResetPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </ScrollToTop>
        )}
      </Router>
      <AppModal />
      <Toaster />
      <ErrorToastManager />
    </AppProvider>
  );
}
