import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

import './style.css';

function AppModal(props) {
  const className = 'app-modal';

  return (
    <Modal
      className={className}
      fullscreen
      open={props.show}
      disableParentScroll
      onClose={props.onClose}
      disableClose={props.disableClose}
    >
      {props.component}
    </Modal>
  );
}

AppModal.propTypes = {
  component: PropTypes.any,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  disableClose: PropTypes.bool
};

export default AppModal;
