import {
  BillJsonApiItem,
  InvoiceJsonApiItem,
  LegJsonApiItem,
  LineItemJsonApiItem,
  OrderJsonApiItem,
  StopInstantJsonApiItem,
  StopJsonApiItem
} from '@drayalliance/types';
import Department from '@client/_blessed/store/entities/departments/model';
import { useContext, createContext } from 'react';
import { DecodedJWTToken } from '../../utils/auth';

/**
 * TODO: Continue typing this out. This only holds popular `appState` entries.
 *   Once properly typed we can remove `extends Record<string, any>` or potentially
 *   change it to make use of `unknown` instead of `any`
 */
export interface AppStateContext extends Record<string, any> {
  // Set on mount
  'auth.userInfo': DecodedJWTToken | null;
  'auth.selectedDepartment': any;
  'ui.leftSidebar.open': boolean;
  'ui.leftSidebar.component': any;
  'ui.leftSidebarForArAp.open': boolean;

  // Get populated as data is fetched
  'bill.id'?: Record<string, BillJsonApiItem> | null;
  'department.id'?: Record<string, Department> | null;
  'invoice.id'?: Record<string, InvoiceJsonApiItem> | null;
  'leg.id'?: Record<string, LegJsonApiItem> | null;
  'lineItem.id'?: Record<string, LineItemJsonApiItem> | null;
  'order.id'?: Record<string, OrderJsonApiItem> | null;
  'stop.id'?: Record<string, StopJsonApiItem> | null;
  'stopInstant.id'?: Record<string, StopInstantJsonApiItem> | null;
}

interface AppContext {
  /**
   * This holds the *entire* app state.
   *
   * Any and everthing will get thrown into here if its a legacy component. Newer components shouldn't have their
   *  state in here as they most likely use their own slices of our redux store
   */
  state: AppStateContext;
  // eslint-disable-next-line no-unused-vars
  executeAction: (...args: any) => any;
}

export const appContext = createContext({} as AppContext);
export const appStateContext = createContext({} as AppStateContext);
export const appActionContext = createContext({} as any);
export const appUIStateContext = createContext({} as any);
export const appUIActionContext = createContext({} as any);
export const appErrorStateContext = createContext({} as any);
export const appErrorActionContext = createContext({} as any);

export const useAppContext = () => useContext(appContext);
export const useAppStateContext = () => useContext(appStateContext);
export const useAppActionContext = () => useContext(appActionContext);
export const useAppUIStateContext = () => useContext(appUIStateContext);
export const useAppUIActionContext = () => useContext(appUIActionContext);
export const useAppErrorStateContext = () => useContext(appErrorStateContext);
export const useAppErrorActionContext = () => useContext(appErrorActionContext);
